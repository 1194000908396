import { ENVIRONMENTS, EnvProps } from 'src/globals/types'

import { LOCAL_URLS, DEV_URLS, SANDBOX_URLS, PRODUCTION_URLS, STAGING_URLS, URL_PARAMS } from 'src/constants'

import { hasValue } from 'src/utils'
import { logger } from 'src/utils/logger'
import { urlParam } from 'src/utils/urls'

class _Environment {
  env: EnvProps
  constructor() {
    this.env = {
      current: ENVIRONMENTS.PRODUCTION,
      defaults: {
        [ENVIRONMENTS.LOCAL]: {
          ...LOCAL_URLS,
        },
        [ENVIRONMENTS.DEVELOPMENT]: {
          ...DEV_URLS,
        },
        [ENVIRONMENTS.SANDBOX]: {
          ...SANDBOX_URLS,
        },
        [ENVIRONMENTS.STAGING]: {
          ...STAGING_URLS,
        },
        [ENVIRONMENTS.PRODUCTION]: {
          ...PRODUCTION_URLS,
        },
      },
    }
  }

  detect() {
    if (hasValue(urlParam(URL_PARAMS.PADDLE_ENVIRONMENT)) && urlParam(URL_PARAMS.PADDLE_ENVIRONMENT) !== '') {
      logger.log('Environment Detected: ' + urlParam(URL_PARAMS.PADDLE_ENVIRONMENT))
      this.set(urlParam(URL_PARAMS.PADDLE_ENVIRONMENT))
    } else {
      logger.log('Environment Detected: ' + this.get())
    }
  }

  get() {
    return this.env.current
  }

  set(environment: ENVIRONMENTS) {
    logger.log('Changing environment to: ' + environment)
    this.env.current = environment
  }

  defaults() {
    return this.env.defaults[this.env.current]
  }
}

export const Environment = new _Environment()
